<script>
import { useStore } from 'vuex'
import { onBeforeMount, defineAsyncComponent, shallowRef, onUpdated, computed } from 'vue'
import { getEverflow, getJornayaLeadIdToken, getTrustedForm, scrollToElement } from '@/util/getters'
import SpeakToAgent from '@/components/quotes-questionnaire-v2/components/SpeakToAgent'
import lvProgressbar from 'lightvue/progress-bar'

const QuoteWaySteps = {
  DateOfBirth: {
    component: shallowRef(defineAsyncComponent(() => import ('../quotes-questionnaire-v2/steps/DateOfBirth'))),
    questionText: 'What is your date of birth?'
  },
  DiagnosedLastThreeYears: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/DiagnosedLastThreeYears'))),
    questionText: 'Have you been diagnosed with any of the following conditions in the last 3 years?'
  },
  YearlyIncome: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/YearlyIncome'))),
    questionText: 'What is your yearly income?'
  },
  Name: {
    component: shallowRef(defineAsyncComponent(() => import('../quotes-questionnaire-v2/steps/Name'))),
    questionText: 'What is your name?'
  },
  Email: {
    component: shallowRef(defineAsyncComponent(() => import('../quotes-questionnaire-v2/steps/Email'))),
    questionText: 'What is your email?'
  },
  Phone: {
    component: shallowRef(defineAsyncComponent(() => import('../quotes-questionnaire-v2/steps/Phone'))),
    questionText: 'What is your phone number?'
  },
  ThankYou: {
    component: shallowRef(defineAsyncComponent(() => import('../shared/ThankYou'))),
    questionText: '',
    doNotShowSpeakToAgent: true
  }
}

export default {
  name: "QuoteWayRoot",

  components: {
    SpeakToAgent,
    lvProgressbar
  },

  setup() {
    const store = useStore()
    const currentStep = computed(() => store.state.currentStep)
    const currentProgress = computed(() => store.state.progress)
    const isLoading = computed(() => store.state.loading)

    const scrollOptions = {
      landScapeSelector: '.main-container',
      landScapeOffset: 70,
      portraitSelector: '#app',
    }

    const goToNextStep = (step) => {
      store.commit('SET_IS_LOADING', true)
      store.dispatch('quote_way/goToNextStep', step)
    }


    onBeforeMount(() => {
      //get zip data from local storage...
      const zip_data = JSON.parse(window.localStorage.getItem('zip_data'))
      if (!zip_data) {
        window.location.href = '/funeral-home-article'
        throw Error('Error, zip not found, returning to zip pre lander page.')
      }

      if (zip_data) {
        store.commit('SET_FIELDS', zip_data)
      }
      //clear local storage
      window.localStorage.clear()

      //get jornaya lead id
      //TODO: Set accurate jornaya lead id, this one is for questionnaire
      window.loadJornayaLeadId('807f6757-b597-40d4-e090-9aa3235e89c0')
      getJornayaLeadIdToken().then(jornaya_id => {
        store.commit('SET_FIELD', {field: 'jornayaId', value: jornaya_id})
      })

      //get everflow
      getEverflow().then((ef_transaction_id) => {
        store.commit('SET_FIELD', {field: 'ef_transaction_id', value: ef_transaction_id})
      })

      //set trusted form
      getTrustedForm().then(({trustedFormUrl, trustedFormId}) => {
        const trusted = [
          {field: 'trustedFormId', value: trustedFormId},
          {field: 'trustedFormUrl', value: trustedFormUrl}
        ]
        store.commit('SET_FIELDS', trusted)
      })

      //set url param fields
      store.commit('SET_FIELDS_FROM_URL')

      goToNextStep('StartPath')
    })

    onUpdated(() => {
      scrollToElement(scrollOptions)
    })

    return {
      isLoading,
      currentProgress,
      currentStep,
      QuoteWaySteps,
      goToNextStep
    }
  }
}
</script>

<template>
  <div class="section-container">
    <div class="wrapper question-root-wrapper">
      <div class="inner-wrapper question-root-inner-wrapper">
        <div class="progress-container">
          <lv-progressbar
            :color="'#00753d'"
            :value="currentProgress"
            style="width: 100%; height: 30px"
          />
        </div>

        <div class="row button-answers-row" v-if="!isLoading">
          <div class="box button-answers-box" v-if="QuoteWaySteps[currentStep].questionText">
            <h2 class="main-heading button-answers-heading">{{QuoteWaySteps[currentStep].questionText}}</h2>
          </div>
        </div>

        <component
          :is="QuoteWaySteps[currentStep].component.value"
          @next-step="goToNextStep"
        >
        </component>

        <SpeakToAgent v-if="!QuoteWaySteps[currentStep].doNotShowSpeakToAgent"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../../assets/styles/component_styles/questionnaire/questionnaire-root.scss';
.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 20px 0 40px 0;
  .main-heading {
    font-size: 32px;
  }
}
.progress-bar {
  width: 100%;
  height: 1.5rem;
  border-radius: 5px;
  background-color: rgba(56, 86, 35, 0.4);
  margin-bottom: 40px;
}

.lv-progressbar--determinate .lv-progressbar__value--animate {
  transition: none;
}

.lv-progressbar--determinate .lv-progressbar--determinate__label {
  font-size: 20px;
  margin-top: 3px;
}

.question-root-wrapper {
  //this overwrites the original greenish background, keep things separate from the first version
  background-color: #fff;
  max-width: 800px;
  border-radius: 0;
  .question-root-inner-wrapper {
    justify-content: flex-start;
  }
}
</style>

<style lang="scss">
.lv-progressbar--determinate .lv-progressbar--determinate__label {
  font-size: 22px;
}
</style>